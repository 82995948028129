import { Reducer } from 'redux'
import { AppState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { TokenResponse } from 'core/graphql/type'
import { UserModel } from 'core/models/userModel'

export const initialState: AppState = {
    init: false,
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.init): {
            const init = action.payload
            return {
                ...state,
                init,
            }
        }
        case getType(actions.setUser): {
            const user: UserModel = action.payload
            return {
                ...state,
                user,
            }
        }
        case getType(actions.setAuthentication): {
            const response: TokenResponse = action.payload
            return {
                ...state,
                user: response.user,
                token: response.token,
            }
        }
        case getType(actions.validateEmail): {
            const hasEmailValidated: boolean = action.payload
            const { user } = state
            if (user) {
                user.hasEmailValidated = hasEmailValidated
            }
            return {
                ...state,
                user,
            }
        }
        default:
            return state
    }
}

export { reducer as appReducer }
