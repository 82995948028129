import React, { ComponentType } from 'react'
import { SnackbarProvider } from 'notistack'
import SnackbarNotifier from 'components/common/snackbarNotifier'

const withNotistack = (ComposedComponent: ComponentType) => {
    const WithNotistack: React.FC = (props) => {
        return (
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SnackbarNotifier />
                <ComposedComponent {...props} />
            </SnackbarProvider>
        )
    }
    return WithNotistack
}

export default withNotistack
