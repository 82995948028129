import { createTheme, responsiveFontSizes } from '@mui/material'

export let theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#36475a',
        },
        secondary: {
            main: '#ad456a',
        },
        background: {
            default: '#36475a',
        },
        error: {
            main: '#cf6679',
        },
        text: {
            primary: '#dde3f4',
            secondary: '#fff',
        },
    },
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        h1: {
            textTransform: 'uppercase',
            fontSize: '2rem',
            color: '#fff',
            textAlign: 'center',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiDialog: {
            defaultProps: {
                scroll: 'body',
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#25313e',
                    padding: 16,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: '#fff',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    'color': '#dde3f4',
                    '&.Mui-focused': {
                        color: '#fff',
                    },
                    '&.Mui-disabled': {
                        color: '#bcc1d0',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    'color': '#fff',
                    '&:before': {
                        borderBottomColor: '#dde3f4 !important',
                    },
                    '&:after': {
                        borderBottomColor: '#dde3f4 !important',
                    },
                    '&.Mui-error:after': {
                        borderBottomColor: '#cf6679 !important',
                    },
                },
                input: {
                    '&.Mui-disabled': {
                        color: '#bcc1d0',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#dde3f4',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    minHeight: 42,
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                elevation: 0,
            },
        },
    },
})
theme = responsiveFontSizes(theme)
