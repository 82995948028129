import * as React from 'react'
import { ComponentType } from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'core/graphql/client'

const withApolloProvider = (ComposedComponent: ComponentType) => {
    const WithApolloProvider: React.FC = ({ ...props }) => {
        return (
            <ApolloProvider client={client}>
                <ComposedComponent {...props} />
            </ApolloProvider>
        )
    }
    return WithApolloProvider
}

export default withApolloProvider
