import * as React from 'react'
import { ComponentType } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { createRootReducer } from 'core/store/reducers'
import { createMiddleware } from 'core/store/middleware'
import mainSaga from 'core/store/sagas'
import createSagaMiddleware from 'redux-saga'

const rootReducer = createRootReducer()
const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, createMiddleware(sagaMiddleware))
sagaMiddleware.run(mainSaga)

const withStoreProvider = (ComposedComponent: ComponentType) => {
    const WithStoreProvider: React.FC = ({ ...props }) => {
        return (
            <Provider store={store}>
                <ComposedComponent {...props} />
            </Provider>
        )
    }
    return WithStoreProvider
}

export default withStoreProvider
