import * as React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from 'core/style/theme'

const withMuiTheme = (ComposedComponent: React.ComponentType) => {
    const WithMuiTheme: React.FC = (props) => (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <ComposedComponent {...props} />
            </ThemeProvider>
        </React.Fragment>
    )
    return WithMuiTheme
}

export default withMuiTheme
