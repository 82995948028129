import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setBearer } from 'core/graphql/client'
import { TokenResponse } from 'core/graphql/type'

function* appSagas() {
    yield takeLatest(getType(actions.initSession), init)
    yield takeLatest(getType(actions.setAuthentication), setAuthentication)
    yield takeLatest(getType(actions.logOut), logOut)
}

function* init(action: ActionType<typeof actions.initSession>) {
    if (action.payload) {
        yield put(actions.init(true))
    }
}

function* setAuthentication(action: ActionType<typeof actions.setAuthentication>) {
    const { token } = action.payload

    const refreshedToken: TokenResponse = yield call(setBearer, token.access_token, token.refresh_token)
    yield put(actions.setAuthentication(refreshedToken))
}

function* logOut() {
    yield put(actions.setUser(undefined))
}

export default appSagas
