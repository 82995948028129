import React from 'react'
import Helmet from 'react-helmet'

const MainLayout: React.FC = ({ children }) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Famiz - solutions pour la famille</title>
            </Helmet>
            {children}
        </>
    )
}

export default MainLayout
