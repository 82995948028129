import { createAction } from 'typesafe-actions'
import { AppActionTypes } from './types'
import { UserModel } from 'core/models/userModel'
import { TokenResponse } from 'core/graphql/type'

export const init = createAction(AppActionTypes.INIT)<boolean>()
export const setUser = createAction(AppActionTypes.SET_USER)<UserModel | undefined>()
export const setAuthentication = createAction(AppActionTypes.SET_AUTHENTICATION)<TokenResponse>()
export const logOut = createAction(AppActionTypes.LOGOUT)<void>()
export const validateEmail = createAction(AppActionTypes.VALIDATE_EMAIL)<boolean>()
