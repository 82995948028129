import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TokenResponse } from 'core/graphql/type'
import { mutations } from 'core/graphql/mutations'

function* sessionSagas() {
    yield takeLatest(getType(actions.initSession), init)
    yield takeLatest(getType(actions.setAuthentication), setAuthentication)
    yield takeLatest(getType(actions.logOut), logOut)

    yield put(actions.initSession(false))
}

function* init(action: ActionType<typeof actions.initSession>) {
    if (!action.payload) {
        if (typeof window !== 'undefined') {
            const accessToken: string | null = yield window.localStorage.getItem('access_token')
            const refreshToken: string | null = yield window.localStorage.getItem('refresh_token')

            if (accessToken && refreshToken) {
                try {
                    const refreshedToken: TokenResponse = yield call(mutations.refreshToken, { refreshToken })
                    yield put(actions.setAuthentication(refreshedToken))
                } catch (error) {
                    console.warn(error)
                }
            }
        }

        yield put(actions.initSession(true))
    }
}

function setAuthentication(action: ActionType<typeof actions.setAuthentication>) {
    const { token } = action.payload
    if (typeof window !== 'undefined' && token.refresh_token) {
        window.localStorage.setItem('access_token', token.access_token)
        window.localStorage.setItem('refresh_token', token.refresh_token)
    }
}

function logOut() {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('refresh_token')
    }
}

export default sessionSagas
