import { UserModel } from 'core/models/userModel'
import { TokenModel } from 'core/models/tokenModel'

export enum AppActionTypes {
    INIT = '@@app/INIT',
    SET_USER = '@@app/SET_USER',
    SET_AUTHENTICATION = '@@app/SET_AUTHENTICATION',
    LOGOUT = '@@app/LOGOUT',
    VALIDATE_EMAIL = '@@app/VALIDATE_EMAIL',
}

export interface AppState {
    init: boolean
    user?: UserModel
    token?: TokenModel
}
