import * as React from 'react'
import { compose } from 'redux'
import MainLayout from './src/components/layouts/mainLayout'
import withMuiTheme from './src/containers/hoc/withMuiTheme'
import withStoreProvider from './src/containers/hoc/withStoreProvider'
import withNotistack from './src/containers/hoc/withNotistack'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import withApolloProvider from './src/containers/hoc/withApolloProvider'

const Root = compose(withMuiTheme, withStoreProvider, withApolloProvider, withNotistack)(MainLayout)

export const wrapPageElement = ({ element, props }) => {
    return <Root pageContext={props.pageContext}>{element}</Root>
}
