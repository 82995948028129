import {
    CheckUserRequest,
    CreateUserRequest,
    LoginUserRequest,
    RefreshTokenRequest,
    TokenResponse,
    UpdateUserRequest,
} from './type'
import { client } from './client'
import { gql } from '@apollo/client'
import { UserModel } from '../models/userModel'

const refreshToken = ({ refreshToken }: RefreshTokenRequest): Promise<TokenResponse> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($refreshToken: String!) {
                    refreshToken(refreshToken: $refreshToken) {
                        user {
                            id
                            username
                            givenName
                            familyName
                            email
                            hasEmailValidated
                            permissions {
                                action
                                subject
                                resource
                            }
                        }
                        token {
                            token_type
                            access_token
                            refresh_token
                            expiry
                        }
                    }
                }
            `,
            variables: {
                refreshToken,
            },
        })
        .then((result) => result.data.refreshToken)
}

const hasUser = ({ username }: CheckUserRequest): Promise<boolean> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($username: String!) {
                    hasUser(username: $username)
                }
            `,
            variables: {
                username,
            },
        })
        .then((result) => result.data.hasUser)
}

const createUser = (user: CreateUserRequest): Promise<UserModel> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($user: NewUser!) {
                    createUser(input: $user) {
                        id
                        username
                        givenName
                        familyName
                        email
                        hasEmailValidated
                        permissions {
                            action
                            subject
                            resource
                        }
                    }
                }
            `,
            variables: {
                user: {
                    username: user.username,
                    email: user.email,
                    familyName: user.familyName,
                    givenName: user.givenName,
                    password: user.password,
                },
            },
        })
        .then((result) => result.data.createUser)
}

const loginUser = (input: LoginUserRequest): Promise<TokenResponse> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($input: LoginUser!) {
                    loginUser(input: $input) {
                        user {
                            id
                            username
                            givenName
                            familyName
                            email
                            hasEmailValidated
                            permissions {
                                action
                                subject
                                resource
                            }
                        }
                        token {
                            token_type
                            access_token
                            refresh_token
                            expiry
                        }
                    }
                }
            `,
            variables: {
                input,
            },
        })
        .then((result) => result.data.loginUser)
}

const updateUser = (input: UpdateUserRequest): Promise<UserModel> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($input: UpdateUser!) {
                    updateUser(input: $input) {
                        id
                        username
                        givenName
                        familyName
                        email
                        hasEmailValidated
                        permissions {
                            action
                            subject
                            resource
                        }
                    }
                }
            `,
            variables: {
                input,
            },
        })
        .then((result) => result.data.updateUser)
}

const validateEmail = (code: string): Promise<boolean> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($code: String!) {
                    validateEmail(code: $code)
                }
            `,
            variables: {
                code,
            },
        })
        .then((result) => result.data.validateEmail)
}

const resendCode = (): Promise<boolean> => {
    return client
        .mutate({
            mutation: gql`
                mutation {
                    resendCode
                }
            `,
        })
        .then((result) => result.data.resendCode)
}

const forgottenPassword = (username: string): Promise<boolean> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($username: String!) {
                    forgottenPassword(username: $username)
                }
            `,
            variables: {
                username,
            },
        })
        .then((result) => result.data.forgottenPassword)
}

const forgottenPasswordUpdate = (username: string, code: string, password: string): Promise<boolean> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($username: String!, $code: String!, $password: String!) {
                    forgottenPasswordUpdate(username: $username, code: $code, password: $password)
                }
            `,
            variables: {
                username,
                code,
                password,
            },
        })
        .then((result) => result.data.forgottenPasswordUpdate)
}

export const mutations = {
    refreshToken,
    hasUser,
    createUser,
    loginUser,
    updateUser,
    validateEmail,
    resendCode,
    forgottenPassword,
    forgottenPasswordUpdate,
}
