import { ApolloClient, createHttpLink, from, InMemoryCache, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TokenResponse } from './type'
import { mutations } from './mutations'
import { onError } from '@apollo/client/link/error'

const httpLink = createHttpLink({
    uri: process.env.GATSBY_API_URL,
    headers: {
        'x-api-key': process.env.GATSBY_API_KEY,
    },
})

export const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
})

export const setBearer = (accessToken: string, refreshToken?: string) => {
    return new Promise<TokenResponse>((resolve) => {
        const requestLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: accessToken ? 'Bearer ' + accessToken : '',
                },
            }
        })
        const responseLink = onError(({ graphQLErrors, forward, operation }) => {
            if (graphQLErrors && refreshToken) {
                for (let err of graphQLErrors) {
                    switch (err.message) {
                        case 'UNAUTHENTICATED':
                            return new Observable((observer) => {
                                mutations
                                    .refreshToken({ refreshToken })
                                    .then((tokenResponse) => {
                                        // Modify the operation context with a new token
                                        const oldHeaders = operation.getContext().headers
                                        operation.setContext({
                                            headers: {
                                                ...oldHeaders,
                                                authorization: 'Bearer ' + tokenResponse.token.access_token,
                                            },
                                        })
                                        const subscriber = {
                                            next: observer.next.bind(observer),
                                            error: observer.error.bind(observer),
                                            complete: () => {
                                                resolve(tokenResponse)
                                                return observer.complete.bind(observer)
                                            },
                                        }

                                        // Retry last failed request
                                        return forward(operation).subscribe(subscriber)
                                    })
                                    .catch((error) => {
                                        // No refresh or client token available, we force user to login
                                        observer.error(error)
                                    })
                            })
                    }
                }
            }
        })

        client.setLink(from([requestLink, responseLink, httpLink]))
    })
}
